import { useEffect } from "react"
import RRAnalytics from "./rr_analytics"

export const IS_PROD =
  global.location && global.location.hostname === "www.religiousreligion.com"

export const useImpression = page => {
  useEffect(() => {
    RRAnalytics.trackPageView(page)
  }, [page])
}
