import React from "react"
import { Link } from "gatsby"

import Routes from "../routes"
import "./header.scss"

const Header = ({ location, menuLinks }) => (
  <header>
    <div
      className="flex-column flex-center"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1rem 1.0rem`,
        paddingTop: 20,
      }}
    >
      <div style={{ fontSize: "1.5rem" }}>
        <Link className="site-title padding-right-20px" to={Routes.HOME}>
          Religious Religion
        </Link>
      </div>
      <div className="padding-top-10px">
        <nav>
          <ul className="flex-center flex-wrap">
            {menuLinks.map(({ name, link }) => (
              <li
                key={`header-${name}`}
                style={{
                  listStyleType: `none`,
                  padding: `0.25rem`,
                }}
              >
                <Link to={link}>{name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  </header>
)

export default Header
