const routes = {
  ABOUT: "/about",
  AGNOSTIC: "/agnostic",
  ATHEISM: "/atheism",
  BIBLE: "/bible",
  BLOG_WHY_RELIGION_IS_BAD: "/blog/why-religion-is-bad",
  CHAT: "/chat",
  CHRISTIANITY: "/christianity",
  CRITICISMS: "/criticisms",
  DAWKINS: "/people/richard-dawkins",
  DEBATES: "/debates",
  DILLAHUNTY: "/people/matt-dillahunty",
  GEORGE_PELL: "/people/george-pell",
  HITCHENS: "/people/christopher-hitchens",
  HOME: "/",
  ISLAM: "/islam",
  MEMES: "/jesus/memes",
  PRIVACY: "/privacy",
  RELIGION_IN_CHINA: "/religion-in-china",
  RESOURCES: "/resources",
  SAM_HARRIS: "/people/sam-harris",
  SEPT_11: "/september-11-attacks",
  TEN_COMMANDMENTS: "/bible/ten-commandments",
  TERMS: "/terms",
  SITEMAP: "/sitemap",
  EVIL_FLOW: "/flows/evil",
}

export default routes
