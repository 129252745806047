import { saveCookie, loadCookie } from "./cookie"

const Cookies = {
  DEVICE_ID: "rr_device_id",
}

const log = (...args) => {
  if (global.log) {
    console.log("[RRA]", ...args)
  }
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
function generateId() {
  let text = ""
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  for (let i = 0; i < 20; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

class Analytics {
  constructor() {
    log("Initializing...")
    this.deviceId = this.identifyDevice()
    log("Device:", this.deviceId)
    this.sessionId = new Date().getTime().toString()
    log("Session:", this.sessionId)
    this.userId = null
  }

  identifyDevice = () => {
    log("[IdentifyDevice]")
    const deviceId = loadCookie(Cookies.DEVICE_ID)
    if (!deviceId) {
      log("[IdentifyDevice] New device found")
      const id = generateId()
      saveCookie(Cookies.DEVICE_ID, id)
      return id
    }
    log("[IdentifyDevice] Know device found", deviceId)
    return deviceId
  }

  identifyUser = userId => {
    log("[IdentifyUser]", userId)
    this.userId = this.userId || userId
  }

  trackPageView = (page, prevPath) => {
    log("[TrackPageView]", page, this.deviceId)
  }

  trackEvent = (eventCategory, eventName, data = {}) => {
    log("[TrackEvent]", eventCategory, eventName, data, this.deviceId)
  }
}

const RRAnalytics = new Analytics()
export default RRAnalytics
