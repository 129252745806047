import React from "react"
import { Link } from "gatsby"
// import {
//   FaPinterest,
//   FaEnvelope,
//   FaTwitter,
//   FaFacebook,
//   FaLinkedin,
// } from "react-icons/fa"
// import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share"

import Routes from "../routes"

import "./shared.scss"

// const shareBlockProps = {
//   url: "https://www.religiousreligion.com",
//   button: ShareButtonCircle,
//   buttons: [
//     { network: "Twitter", icon: FaTwitter },
//     { network: "Facebook", icon: FaFacebook },
//     { network: "Email", icon: FaEnvelope },
//     {
//       network: "Pinterest",
//       icon: FaPinterest,
//       media: "https://www.religiousreligion.com",
//     },
//     { network: "Linkedin", icon: FaLinkedin },
//   ],
//   text: `Learn about religion, and it's alternatives.`,
//   // longtext: `Learn about the origins of religion, and what they all mean. And explore alternatives like atheism and agnosticism.`,
// }

const miscLinks = [
  { link: Routes.HOME, label: "Home" },
  { link: Routes.DEBATES, label: "Best religious debates" },
  {
    link: Routes.CRITICISMS,
    label: "Religious criticisms, quotes, and anti-religion",
  },
  { link: Routes.MEMES, label: "Jesus and Christian memes" },
  { link: Routes.RESOURCES, label: "More resources and content" },
  { link: Routes.EVIL_FLOW, label: "Why does evil exist?" },
]

const learnAboutLinks = [
  { link: Routes.ATHEISM, label: "Atheism & atheists" },
  { link: Routes.AGNOSTIC, label: "Agnosticism & agnostics" },
  { link: Routes.BIBLE, label: "Bible" },
  { link: Routes.TEN_COMMANDMENTS, label: "The Ten Commandments" },
  { link: Routes.RESOURCES, label: "Resources" },
  { link: Routes.CRITICISMS, label: "Criticisms" },
  { link: Routes.SEPT_11, label: "September 11 attacks" },
]

const peopleLinks = [
  { link: Routes.HITCHENS, label: "Christopher Hitchens" },
  { link: Routes.SAM_HARRIS, label: "Sam Harris" },
  { link: Routes.GEORGE_PELL, label: "Ex-Cardinal George Pell" },
  { link: Routes.DAWKINS, label: "Richard Dawkins" },
  { link: Routes.DILLAHUNTY, label: "Matt Dillahunty" },
]

const siteLinks = [
  { link: Routes.ABOUT, label: "About us" },
  { link: Routes.PRIVACY, label: "Privacy" },
  { link: Routes.TERMS, label: "Terms" },
  { link: Routes.SITEMAP, label: "Site map" },
]

const blogLinks = [
  { link: Routes.BLOG_WHY_RELIGION_IS_BAD, label: "Why religion is bad" },
]

const links = [
  { title: "Learn more about", links: learnAboutLinks },
  { title: "People", links: peopleLinks },
  { title: "Resources", links: miscLinks },
  { title: "Site links", links: siteLinks },
  { title: "Blog", links: blogLinks },
]

const Footer = () => (
  <div>
    <br />
    {/* <ShareBlockStandard {...shareBlockProps} /> */}

    <br />
    <footer>
      <div className="flex-wrap flex-space-between">
        {links.map(({ title, links: sectionLinks }, sectionIndex) => (
          <div
            key={`section${sectionIndex}`}
            style={{ maxWidth: 200, marginTop: 20 }}
          >
            <h4>{title}</h4>
            {sectionLinks.map(({ link, label }) => (
              <span key={label}>
                <div className="link">
                  <Link to={link} style={{ padding: 2 }}>
                    {label}
                  </Link>
                </div>
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="padding-top-10px" style={{ textAlign: "right" }}>
        <strong>
          Religious Religion{" "}
          <p style={{ display: "inline" }}>© {new Date().getFullYear()}</p>
        </strong>
      </div>
    </footer>
  </div>
)

export default Footer
